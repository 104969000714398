.contact {
    font-family: 'Courier New', monospace;
    color: #0f0;
    background: #000;
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .contact-form .input-group {
    margin-bottom: 20px;
  }
  
  .contact-form .input-group label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contact-form .input-group input,
  .contact-form .input-group textarea {
    width: 100%;
    padding: 10px;
    font-family: 'Courier New', monospace;
    color: #0f0;
    background: #000;
    border: 1px solid #0f0;
  }
  
  .contact-form .input-group button {
    padding: 10px 20px;
    font-family: 'Courier New', monospace;
    color: #000;
    background: #0f0;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .contact-form .input-group button:hover {
    color: #0f0;
    background: #000;
    border: 1px solid #0f0;
  }
  