.projects-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #0f0;
    background: #000;
    font-family: 'Courier New', monospace;
  }
  
  .project-slide {
    text-align: center;
    padding: 20px;
  }
  
  .project-image-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
  }
  
  .project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #0f0;
  }
  
  .project-slide h3 {
    font-size: 24px;
    margin: 10px 0;
    border-bottom: 2px solid #0f0;
  }
  
  .project-slide p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Slick dots styling */
  .slick-dots li button:before {
    color: #0f0;
  }
  