body {
  font-family: 'Courier New', monospace;
  color: #0f0;
  background: #000;
  margin: 0;
  padding: 60px 0 0; /* Add padding to the top to avoid overlapping with the navbar */
}

.home {
  height: calc(100vh - 60px); /* Adjust the height considering the navbar */
  display: flex;
  align-items: center;
  padding: 0 10%;
}








.home-content {
  max-width: 800px;
  text-align: center;
}

.home-content .Typewriter__wrapper {
  font-size: 30px;
  color: #0f0;
  margin: 20px 0;
}

.glitch-btn {
  background: #00ffaa;
  color: #000;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  border: 2px solid #00ffaa;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: none;
  letter-spacing: 1px;
  display: inline-block;
}

.glitch-btn:hover {
  background: #0f0;
  color: #000;
  border: 2px solid #0f0;
  box-shadow: 0 0 10px #0f0, 0 0 40px #0f0, 0 0 80px #0f0;
}
.glitch-btn::before,
.glitch-btn::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0f0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  animation: glitch 1s infinite linear alternate-reverse;
}

.glitch-btn::before {
  left: 2px;
  text-shadow: -2px 0 #ff00ff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  animation-delay: 0.25s;
}

.glitch-btn::after {
  left: -2px;
  text-shadow: -2px 0 #00ffff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  animation-delay: 0.75s;
}

@keyframes glitch {
  0% {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  }
  25% {
    clip-path: polygon(25% 0, 75% 0, 75% 100%, 25% 100%);
  }
  50% {
    clip-path: polygon(5% 0, 95% 0, 95% 100%, 5% 100%);
  }
  75% {
    clip-path: polygon(25% 0, 75% 0, 75% 100%, 25% 100%);
  }
  100% {
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  }
}

/* Typewriter container */
.typewriter-container {
  background: rgba(0, 255, 0, 0.1);
  border: 2px solid #0f0;
  padding: 10px;
  border-radius: 5px;
}
#canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.hire-me, .lets-talk {
  margin: 20px;
  padding: 10px;
  background-color: #111;
  border: 2px solid #0f0;
}

.hire-me h1, .lets-talk h1 {
  color: #0f0;
  font-size: 24px;
}

.hire-me form input, .hire-me form button, .lets-talk div {
  color: #0f0;
  background-color: #222;
  border: 2px solid #0f0;
  margin: 5px;
  padding: 5px;
}

.hire-me form button, .lets-talk button {
  cursor: pointer;
}
.chat-container {
  width: 300px;
  margin: 0 auto;
}

.chat-window {
  height: 400px;
  border: 1px solid #ccc;
  overflow: auto;
  padding: 10px;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.user {
  text-align: right;
  color: blue;
}

.bot {
  text-align: left;
  color: green;
}
.home-content .Typewriter__wrapper {
  font-size: 30px;
  color: #0f0;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
}

.home-content .Typewriter__wrapper::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ff00ff;
  background: black;
  overflow: hidden;
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch 2s infinite;
}
@keyframes glitch {
  0% {
    clip: rect(83px, 9999px, 86px, 0);
  }
  5% {
    clip: rect(66px, 9999px, 52px, 0);
  }
  10% {
    clip: rect(40px, 9999px, 85px, 0);
  }
}
.home-sci a img {
  transition: none;
}

.home-sci a:hover img {
  transform: scale(1.2);
}